import React, { useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import SvgButton from "./Custom/CustomButton";
import PushPinIcon from "@mui/icons-material/PushPin";
import { keyframes } from "@mui/system";

import "../styles/opentowork.css";

// Keyframes for diagonal pin drop animation (from top-left corner)
const diagonalPinDropFromTopLeft = keyframes`
  0% {
    transform: translate(-200px, -200px) rotate(0deg); /* Start from outside the top-left */
  }
  100% {
    transform: translate(0, 0) rotate(-30deg); /* Final position at top-left, slightly rotated */
  }
`;

// Keyframes for the shake effect after pinning
const shakeEffect = keyframes`
  0% { transform: translate(0, 0) rotate(-30deg); }
  20% { transform: translate(2px, -2px) rotate(-35deg); }
  40% { transform: translate(-2px, 2px) rotate(-25deg); }
  60% { transform: translate(1px, -1px) rotate(-32deg); }
  80% { transform: translate(-1px, 1px) rotate(-28deg); }
  100% { transform: translate(0, 0) rotate(-30deg); }
`;

// Keyframes for paper entrance animation (slide in and scale up)
const paperEntrance = keyframes`
  0% {
    opacity: 0;
    transform: translateY(50px) scale(0.9); /* Slide up and slightly scaled down */
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1); /* Final position and size */
  }
`;

const OpenToWork = () => {
  const pinRef = useRef(null);
  const [animatePin, setAnimatePin] = useState(false);
  const [animatePaper, setAnimatePaper] = useState(false);

  // Detect when the section comes into view
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setAnimatePin(true); // Trigger the pin animation
            setAnimatePaper(true); // Trigger the paper animation
          }
        });
      },
      { threshold: 0.5 } // ********* Timing: Trigger when the entire section is visible *********
    );

    if (pinRef.current) {
      observer.observe(pinRef.current);
    }

    return () => {
      if (pinRef.current) {
        observer.unobserve(pinRef.current);
      }
    };
  }, []);

  return (
    <section id="opentowork" ref={pinRef} class="otwCard">
      <Box
        className="otwCard-box"
        sx={{
          position: "relative", // To position the pin
          backgroundColor: "#fdfdfd", // Paper-like color
          padding: "50px 20px",
          marginTop: 5,
          marginBottom: 5,
          borderRadius: "10px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Light shadow for depth
          border: "1px solid #e0e0e0", // Subtle border to mimic paper
          fontFamily: "'Roboto', sans-serif", // Paper-style font
          opacity: animatePaper ? 1 : 0, // Initially hidden
          transform: animatePaper
            ? "translateY(0) scale(1)"
            : "translateY(50px) scale(0.9)", // Initial position and size
          animation: animatePaper
            ? `${paperEntrance} 1s ease-out forwards`
            : "none", // Paper entrance animation
        }}
      >
        {/* Pin Icon in the top-left corner with diagonal animation */}
        <PushPinIcon
          sx={{
            position: "absolute",
            top: "-10px",
            left: "-10px",
            color: "#ff4081", // Pin color
            fontSize: "40px", // Size of the Pin
            transform: "rotate(0deg)", // Initial rotation
            visibility: animatePin ? "visible" : "hidden", // Hide pin initially
            animation: animatePin
              ? `${diagonalPinDropFromTopLeft} 0.8s ease-out forwards, ${shakeEffect} 0.5s ease-out 0.8s` // Combine diagonal movement with shake
              : "none", // Trigger the animation
          }}
        />

        <Typography
          variant="h2"
          textAlign="center"
          sx={{
            fontWeight: "bold",
            letterSpacing: "0.02em",
            fontSize: "32px",
            color: "#333333", // Darker text for contrast with paper background
          }}
        >
          #OPENTOWORK
        </Typography>
        <br />
        <Typography
          variant="subtitle1"
          textAlign="center"
          sx={{
            fontWeight: "bold",
            letterSpacing: "0.028em",
            color: "#555555",
          }}
        >
          SOFTWARE ENGINEER ∙ FULL STACK DEVELOPER ∙ PYTHON DEVELOPER ∙
          JAVASCRIPT DEVELOPER ∙ BACKEND DEVELOPER
        </Typography>
        <br />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 4,
          }}
        >
          <SvgButton
            href="https://docs.google.com/forms/d/e/1FAIpQLSdtNJrL3SDDl-mJxd1O3HTznUa1xQv9JXeq72Z8mgnXrYHStw/viewform"
            target="_blank"
            sx={{
              "--main-color": "darkslategrey",
              letterSpacing: "1px",
              "&:hover": {
                "--main-color": "#ff4081",
              },
            }}
          >
            CONTACT ME
          </SvgButton>
        </Box>
      </Box>
    </section>
  );
};

export default OpenToWork;
